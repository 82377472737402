var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advisor-form"
  }, [_c('div', {
    staticClass: "advisor-form__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "outline black",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 1), _c('div', {
    staticClass: "advisor-form__box"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "advisor-form__content"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-5",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('div', {
          staticClass: "actions"
        }, [_c('sygni-toggle-switch', {
          attrs: {
            "label": "COMPANY REPRESENTATIVE"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Add new participant")])]), _c('div', {
    staticClass: "options options--company"
  }, [_c('div', {
    staticClass: "options__container"
  }, [_c('div', {
    staticClass: "options__single"
  }, [_c('div', {
    staticClass: "tax-id"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "TAX ID"
    }
  })], 1)]), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "POSITION"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "COMPANY NAME"
    }
  })], 1)])]), _c('div', {
    staticClass: "options"
  }, [_c('div', {
    staticClass: "options__container"
  }, [_c('div', {
    staticClass: "options__single"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "FIRST NAME"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "SURNAME"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "EMAIL"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'ROLE',
      "searchable": true,
      "value": 'Signer',
      "options": [{
        label: 'Signer',
        value: 'Signer'
      }]
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'SIGNATURE TYPE',
      "searchable": true,
      "value": 'E-signature Autenti',
      "options": [{
        label: 'E-signature Autenti',
        value: 'E-signature Autenti'
      }]
    }
  }), _c('div', {
    staticClass: "checkboxes"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "value": true,
      "label": "Authorization via SMS code"
    }
  }), _c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "value": true,
      "label": "Secure access to the document with an SMS code"
    }
  }), _c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "value": true,
      "label": "ZIP file secured with an SMS code"
    }
  })], 1), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "PHONE NUMBER"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [!_vm.isMarketplaceAccountant ? _c('sygni-rounded-button', {
    staticClass: "filled red button secondary mt-5"
  }, [_vm._v("Add participant")]) : _vm._e(), _c('div', {
    attrs: {
      "clas": "router-link"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('div', {
    staticClass: "cancel-button mt-4"
  }, [_vm._v("Cancel")])])], 1)])])], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }